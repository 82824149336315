import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectTableauReservationDetailsCustomState =
  createFeatureSelector<State>('tableauReservationDetails');

export const selectTableauReservationRooms = featureAdapter.getSelectors(
  selectTableauReservationDetailsCustomState,
).selectAll;

export const selectTableuReservationRoom = (roomReservationId: string) =>
  createSelector(
    selectTableauReservationDetailsCustomState,
    (state) => state.entities[roomReservationId],
  );

export const selectTableuReservationDetailsError = createSelector(
  selectTableauReservationDetailsCustomState,
  (state) => state.error,
);

export const selectTableauReservationDetailsIsLoading = createSelector(
  selectTableauReservationDetailsCustomState,
  (state) => !!state.loadings.length,
);
