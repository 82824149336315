import { createAction, props } from '@ngrx/store';

import { IAvailableRoomRequest } from '../../core/models/api/generics/available-room/available-room.model';
import { AvailableRoom } from '../../models';

export const loadRequest = createAction(
  '[Available Rooms] Load Request',
  props<IAvailableRoomRequest>(),
);
export const loadSuccess = createAction(
  '[Available Rooms] Load Success',
  props<{
    availableRooms: AvailableRoom[];
  }>(),
);
export const loadFailure = createAction(
  '[Available Rooms] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Available Rooms] Reset State');
