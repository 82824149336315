import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { AvailableRoomService } from '../../core/services/available-room.service';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { AvailableRoom } from '../../models';

import * as fromActions from './actions';

@Injectable()
export class AvailableRoomsStoreEffects {
  constructor(
    private actions$: Actions,
    private dataService: AvailableRoomService,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ type, ...payload }) =>
        this.dataService.loadAvailableRoom(payload).pipe(
          map(({ data }: IResponseSuccess<AvailableRoom[]>) =>
            fromActions.loadSuccess({
              availableRooms: data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
