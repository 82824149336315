import { get } from 'lodash';

import { FirstRoomGuest } from '../../models/objects/first-room-guest';
import { IRoomModel } from '../../shared/add-bill-modal/models/rooms.model';

/**
 *
 * @description La seguente funzione accetta come parametro una room di una prenotazione
 * e restituisce un oggetto di tipo FirstRoomGuest se trova un guest che ha priorità sul booker.
 * Ritornerà NULL se il referente della prenotazione è il booker.
 *
 */

export const findFirstRoomGuest = (room: IRoomModel): FirstRoomGuest => {
  if (!room) {
    return null;
  }

  const guests = get(room, 'guests');

  if (!guests || !guests.length) {
    return null;
  }

  const leader = getLeaderGuest(guests);

  if (leader) {
    const { customer, guest_type_id, customer_id } = leader;
    return {
      guest_type_id,
      customer_id,
      full_name: `${get(customer, 'name')} ${get(customer, 'surname')}`,
    };
  }

  const {
    customer: firstCustomer,
    guest_type_id: firstGuestTypeId,
    customer_id: firstCustomerId,
  } = guests[0];

  return {
    full_name: `${get(firstCustomer, 'name')} ${get(firstCustomer, 'surname')}`,
    guest_type_id: firstGuestTypeId,
    customer_id: firstCustomerId,
  };
};

export function getLeaderGuest(
  guests: Array<{ guest_type_id: number; main_guest: boolean } & any>,
) {
  const mainGuest = guests?.find((guest) => guest?.main_guest);

  const mainGuestByGuestType = guests?.find(
    (guest) => guest.guest_type_id === 2 || guest.guest_type_id === 3,
  );

  return mainGuest || mainGuestByGuestType;
}
