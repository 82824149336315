import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getLeaderGuest } from '../../core/helpers/find-first-room-guest';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { handleSplit } from '../../helpers/handle-split';
import { Reservation, TableauReservationDetails } from '../../models';
import { TableauReservationDetailsService } from '../../services';
import { ReservationDetailsService } from '../../services/reservation-details.service';

import * as fromActions from './actions';

@Injectable()
export class TableauReservationDetailsStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private reservationDetailsService: ReservationDetailsService,
    private tableauReservationDetailsService: TableauReservationDetailsService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ reservation_id }) =>
        this.reservationDetailsService.load(reservation_id).pipe(
          map(({ data: [reservation] }: IResponseSuccess<Reservation[]>) => {
            return fromActions.loadSuccess({
              accommodations: this.parseReservationAccommodations(reservation),
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ reservation_accommodation_room_id, reservation_id }) =>
        this.tableauReservationDetailsService
          .loadReservation(reservation_id, reservation_accommodation_room_id)
          .pipe(
            map(({ data }) => {
              return fromActions.loadDetailsSuccess({
                accommodation: data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadDetailsFailure(error));
            }),
          ),
      ),
    ),
  );

  setKeepAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setKeepAccommodationRequest),
      switchMap((params) =>
        this.reservationDetailsService.setKeepAccommodation(params).pipe(
          map(() => {
            return fromActions.setKeepAccommodationSuccess(params);
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.setKeepAccommodationFailure(error));
          }),
        ),
      ),
    ),
  );

  setCheckinStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCheckinStatusRequest),
      switchMap((params) =>
        this.reservationDetailsService.updateCheckinCheckout(params).pipe(
          map(() => {
            return fromActions.setCheckinStatusSuccess(params);
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.setCheckinStatusFailure(error));
          }),
        ),
      ),
    ),
  );

  private parseReservationAccommodations(
    reservation: Reservation,
  ): Partial<TableauReservationDetails>[] {
    const { accommodations, company, status, booker } = reservation;

    return handleSplit(accommodations).map((reservationAccommodation: any) => {
      const guest =
        getLeaderGuest(reservationAccommodation.guests) ||
        first(reservationAccommodation.guests);

      const { tableau } = reservationAccommodation.room;

      return {
        id: reservationAccommodation.room.id,
        accommodation_id: reservationAccommodation.accommodation_id,
        reservation_accommodation_id: reservationAccommodation.id,
        reservation_id: reservationAccommodation.reservation_id,
        roomreservation_id: reservationAccommodation.roomreservation_id,
        reservation_accommodation_room_id: reservationAccommodation.room.id,
        accommodation_tableau_number_id:
          reservationAccommodation.room.tableau?.id,
        checkin: reservationAccommodation.room.checkin,
        checkout: reservationAccommodation.room.checkout,
        company,
        status,
        guest: guest && {
          name: guest.customer?.name,
          surname: guest.customer?.surname,
          facebook: '',
        },
        booker: {
          id: booker.id,
          name: booker.name,
          surname: booker.surname,
          email: booker.detail?.email,
          telephone: booker.detail?.telephone,
          facebook: booker.detail?.facebook,
        },
        tableau_number: tableau && {
          id: tableau.id,
          label: tableau.label,
          order: null,
          common_id: null,
        },
      };
    });
  }
}
