import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { TableauReservationDetails } from '../../models';

export const featureAdapter = createEntityAdapter<TableauReservationDetails>({
  selectId: (model) => model.roomreservation_id,
});

export interface State extends EntityState<TableauReservationDetails> {
  loadings: string[];
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  loadings: [],
  error: null,
});
