import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, StoreModule } from '@ngrx/store';

import { TableauReservationDetailsStoreEffects } from './effects';
import {
  tableauReservationDetailsReducerFactory,
  TableauReservationDetailsStoreReducer,
} from './reducer';
import { State } from './state';

const REDUCER_TOKEN = new InjectionToken<ActionReducer<Map<string, State>>>(
  'Tableau Reservation Details State Reducer',
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('tableauReservationDetails', REDUCER_TOKEN),
    EffectsModule.forFeature([TableauReservationDetailsStoreEffects]),
  ],
  providers: [
    TableauReservationDetailsStoreReducer,
    {
      provide: REDUCER_TOKEN,
      deps: [TableauReservationDetailsStoreReducer],
      useFactory: tableauReservationDetailsReducerFactory,
    },
  ],
})
export class TableauReservationDetailsStoreModule {}
